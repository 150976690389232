<template>
  <VList
    :rows="rows"
    :headers="headers"
    display-pagination
    :is-loading="isLoading"
    :pagination="pagination"
    @click:attestation="onClickRedirectToAttestation"
    @click:training="onClickRedirectToTraining"
    @click:learner="onClickRedirectToLearner"
    @click:registration="onClickRedirectToRegistration"
    @update:current_page="onUpdateCurrentPage"
    @update:per_page="onUpdatePerPage"
  >
    <template #title>
      {{ $t("app.attestations", 2) }}
    </template>

    <template #title-actions>
      <VDropdown
        dropdown-menu-class="w-64"
        :selected="selectedOption"
        :options="options"
        @click="selectedOption = $event"
      >
        <template #option="{ option }">
          <div class="cursor-pointer p-2 truncate">
            {{ option.text }}
          </div>
        </template>
      </VDropdown>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.image="{ item }">
      <VImage
        :src="item.displayed_avatar"
        :color="item.colour"
        :name="item.displayed_name"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.learner="{ item }">
      <div>
        <div class="font-medium whitespace-nowrap">
          {{ item.displayed_learner }}
        </div>

        <div class="text-gray-600 text-xs truncate max-w-prose mt-0.5">
          @{{ item.displayed_username }}
        </div>
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.attestation="{ item }">
      <div>
        <div class="font-medium whitespace-nowrap">
          {{ item.displayed_attestation }}
        </div>

        <div class="text-gray-600 text-xs truncate max-w-prose mt-0.5">
          {{ item.displayed_attestation_details }}
        </div>
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.training="{ item }">
      <div>
        <div class="font-medium whitespace-nowrap">
          {{ item.displayed_training }}
        </div>

        <div class="version mt-0.5">
          {{ $t("app.versions") }} {{ item.displayed_training_version }}
        </div>
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.status="{ item }">
      <div class="flex justify-center">
        <VChip
          :text="item.displayed_status"
          :class="getStatusColor(item.status)"
        />
      </div>
    </template>
  </VList>
</template>

<script>
import { onMounted, watch, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
// Composables
import useColor from "@/composables/useColor";
import usePaginate from "@/composables/usePaginate";
import useReadMultiple from "@/composables/useReadMultiple";
import useTexts from "@/composables/useTexts";
import useDisplay from "@/composables/useDisplay";
// Components
import VChip from "@/components/VChip";
import VImage from "@/components/VImage";
import VDropdown from "@/components/VDropdown";
import VList from "@/components/tables/VList";

export default {
  components: {
    VChip,
    VList,
    VImage,
    VDropdown
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    resource: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();
    const router = useRouter();

    // Constants
    const options = [
      {
        text: t("app.DEFAULT_SUBMITTER"),
        value: "DEFAULT_SUBMITTER"
      },
      {
        text: t("app.DEFAULT_APPROVER"),
        value: "DEFAULT_APPROVER"
      },
      {
        text: t("app.TRAINING_SUBMITTER"),
        value: "TRAINING_SUBMITTER"
      },
      {
        text: t("app.TRAINING_APPROVER"),
        value: "TRAINING_APPROVER"
      },
      {
        text: t("app.REGISTRATION_ATTESTATION_SUBMITTED"),
        value: "REGISTRATION_ATTESTATION_SUBMITTED"
      },
      {
        text: t("app.REGISTRATION_ATTESTATION_APPROVED"),
        value: "REGISTRATION_ATTESTATION_APPROVED"
      }
    ];

    // Data
    const selectedOption = ref(options[0].value);

    // Composables
    const { data, isLoading, read, pagination } = useReadMultiple();
    const { getStatusColor } = useColor();
    const { getText } = useTexts();
    const { getAvatarURL, trimHTML } = useDisplay();

    // Computed
    const rows = computed(() => {
      const trainingOptions = ["TRAINING_SUBMITTER", "TRAINING_APPROVER"];
      const registrationOptions = [
        "REGISTRATION_ATTESTATION_SUBMITTED",
        "REGISTRATION_ATTESTATION_APPROVED"
      ];

      if (trainingOptions.includes(selectedOption.value)) {
        return data.value?.map(row => ({
          ...row,
          rowClass: "cursor-pointer",
          attestation_id: row?.attestation?.id,
          training_id: row?.training?.id,
          displayed_name: getText(row?.attestation?.texts, "name"),
          displayed_attestation: getText(row?.attestation?.texts, "name"),
          // eslint-disable-next-line
          displayed_attestation_details: trimHTML(getText(row?.attestation?.texts, "details")),
          displayed_default_submit_by: row?.attestation?.default_submit_by,
          // eslint-disable-next-line
          displayed_training: getText(row?.training?.texts, "name"),
          displayed_training_version: row?.training?.version,
          // eslint-disable-next-line
          displayed_status: row?.attestation?.status ? t(`app.${row?.attestation?.status}`) : ""
        }));
      }

      if (registrationOptions.includes(selectedOption.value)) {
        return data.value?.map(row => ({
          ...row,
          attestation_id: row?.attestation?.id,
          training_id: row?.registration?.training?.id,
          registration_id: row?.registration?.id,
          learner_id: row?.registration?.learner?.id,
          // eslint-disable-next-line
          displayed_name: `${row?.registration?.learner?.firstname} ${row?.registration?.learner?.lastname}`,
          // eslint-disable-next-line
          displayed_learner: `${row?.registration?.learner?.firstname} ${row?.registration?.learner?.lastname}`,
          displayed_username: row?.registration?.learner?.username,
          // eslint-disable-next-line
          displayed_avatar: getAvatarURL(row?.registration?.learner?.avatar?.view_path),
          displayed_attestation: getText(row?.attestation?.texts, "name"),
          // eslint-disable-next-line
          displayed_attestation_details: trimHTML(getText(row?.attestation?.texts, "details")),
          // eslint-disable-next-line
          displayed_training: getText(row?.registration?.training?.texts, "name"),
          displayed_training_version: row?.registration?.training?.version,
          // eslint-disable-next-line
          displayed_status: row?.registration?.status ? t(`app.${row?.registration?.status}`) : ""
        }));
      }

      return data.value?.map(row => ({
        ...row,
        rowClass: "cursor-pointer",
        attestation_id: row?.id,
        displayed_name: getText(row?.texts, "name"),
        displayed_attestation: getText(row?.texts, "name"),
        displayed_attestation_details: trimHTML(getText(row?.texts, "details")),
        displayed_status: row.status ? t(`app.${row.status}`) : ""
      }));
    });
    const documentTitle = computed(() => {
      // eslint-disable-next-line
      const title = `${t("app.attestations", 2)} - ${t("app.instructors", 2)} - ${t("app.teaching")}`;

      const { firstname, lastname } = props.resource ?? {};
      if (firstname && lastname) {
        return `${firstname} ${lastname} - ${title}`;
      }

      return title;
    });
    const headers = computed(() => {
      const trainingOptions = ["TRAINING_SUBMITTER", "TRAINING_APPROVER"];
      const registrationOptions = [
        "REGISTRATION_ATTESTATION_SUBMITTED",
        "REGISTRATION_ATTESTATION_APPROVED"
      ];

      if (trainingOptions.includes(selectedOption.value)) {
        return [
          {
            value: "image",
            class: "w-10"
          },
          {
            text: t("app.name"),
            value: "attestation"
          },
          {
            text: t("app.default_submit_by"),
            value: "displayed_default_submit_by"
          },
          {
            text: t("app.trainings"),
            value: "training"
          },
          {
            text: t("app.status"),
            value: "status",
            class: "w-40"
          }
        ];
      }

      if (registrationOptions.includes(selectedOption.value)) {
        return [
          {
            value: "image",
            class: "w-10"
          },
          {
            text: t("app.name"),
            value: "learner",
            cellClass: "cursor-pointer"
          },
          {
            text: t("app.attestations"),
            value: "attestation",
            cellClass: "cursor-pointer"
          },
          {
            text: t("app.trainings"),
            value: "training",
            cellClass: "cursor-pointer"
          },
          {
            text: t("app.status"),
            value: "status",
            class: "w-40"
          }
        ];
      }

      return [
        {
          value: "image",
          class: "w-10"
        },
        {
          text: t("app.name"),
          value: "attestation"
        },
        {
          text: t("app.default_submit_by"),
          value: "default_submit_by"
        },
        {
          text: t("app.status"),
          value: "status",
          class: "w-40"
        }
      ];
    });

    // Methods
    const getData = async () => {
      await read({
        endpoint: "teaching.instructors.attestations",
        pathParams: {
          id: props.id,
          type: selectedOption.value
        },
        showToaster: false
      });
    };

    const onClickRedirectToAttestation = row => {
      const id = row?.attestation_id;

      router.push({
        name: "teaching-attestations-overview",
        params: { id }
      });
    };

    const onClickRedirectToTraining = row => {
      const id = row?.training_id;

      router.push({
        name: "teaching-trainings-overview",
        params: { id }
      });
    };

    const onClickRedirectToLearner = row => {
      const id = row?.learner_id;

      router.push({
        name: "teaching-learners-overview",
        params: { id }
      });
    };

    const onClickRedirectToRegistration = row => {
      const id = row?.registration_id;

      router.push({
        name: "teaching-registrations-trainings-details",
        params: { id }
      });
    };

    // eslint-disable-next-line
    const { onUpdatePerPage, onUpdateCurrentPage } = usePaginate(pagination, getData);

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    // Watch
    watch(selectedOption, async () => {
      await getData();
    });

    return {
      selectedOption,
      options,
      documentTitle,
      rows,
      headers,
      onClickRedirectToAttestation,
      onClickRedirectToTraining,
      onClickRedirectToLearner,
      onClickRedirectToRegistration,
      // useReadMultiple
      data,
      isLoading,
      pagination,
      // usePaginate
      onUpdatePerPage,
      onUpdateCurrentPage,
      // useColor
      getStatusColor
    };
  }
};
</script>
